<template>
  <div class="header-nav">
    <div class="left" v-if="goBack">
      <div class="back" @click="returnBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <span>{{title}}</span>
    <div class="right">
      <slot name='extend'></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerInner',
  props: {
    goBack: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    title: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  methods: {
    returnBack () {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 750px;
  margin: 0 auto;
  height: 88px;
  z-index: 100;
  background: #fff;
  i {
    @include sc(32px, #2a2a2a);
  }
  span {
    display: block;
    text-align: center;
    line-height: 88px;
    @include sc(32px, $mainFontColor);
  }
  .left, .right {
    position: absolute;
    z-index: 103;
    @include ct;
  }
  .left {
    left: 20px;
    .back {
      @include extendClick
    }
  }
  .right {
    right: 20px;
  }
}
.header-nav:after {
  @include border-bpx(100%, 1px, #f0f0f0);
}
</style>
