<template>
  <div class="about">
    <header-nav title="关于我们" />
    <div class="about-content">
      <div class="app_icon">
        <img src="@/assets/images/public/app_icon.png" alt />
        <dl>
          <dt>数亿付 v1.1</dt>
          <dd class="product"></dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/header/HeaderNav'

export default {
  name: 'about',
  components: {
    HeaderNav
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../styles/mixin";
  .about {
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 750px;
    margin: 0 auto;
    background: #fff;
    .header {
      position: relative;
    }
    .about-content {
      padding-top: 138px;
      .app_icon {
        img {
          width: 2rem;
          height: 2rem;
          display: block;
          margin: 0 auto;
        }
        dl {
          padding-top: 30px;
          dt {
            text-align: center;
            @include sc(26px, $twoFontColor);
            padding-bottom: 15px;
          }
          .product {
            text-indent: 2em;
            text-align: justify;
            font-size: 26px;
            padding: 20px 30px;
            line-height: 46px;
          }
        }
      }
    }
  }
</style>
